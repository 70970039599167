import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { firstValueFrom, map } from 'rxjs';
import { NotFoundGuard } from '../../guards/not-found.guard';
import { getAllRouteData } from '../../lib/get-all-route-data';
import { getFullPath } from '../../lib/get-full-path';
import { PICFLOW_STORYBLOK } from '../../picflow-storyblok';
import { AffiliateService } from '../../services/affiliate.service';
import { RedirectService } from '../../services/redirect.service';

const isInternalLink = (url: string) => {
  if (!url) return false;
  return url.startsWith('/');
};

const redirect = (url: string, redirectType: 'permanent' | 'temporary', redirectService: RedirectService) => {
  if (isInternalLink(url)) {
    const path = url
      .split('?')[0]
      .split('#')[0]
      .split('/')
      .filter(a => a);
    const searchString = url.split('?')[1]?.split('#')[0];
    const searchParams = new URLSearchParams(searchString);
    const query = {};
    for (const [key, value] of searchParams.entries()) {
      query[key] = value;
    }
    return redirectService.redirectTo(path, { queryParams: query, redirectType });
  }

  return redirectService.externalRedirectTo(url, redirectType);
};

export const storyblokStoryGuard: CanActivateFn = async route => {
  const storyblokService = inject(PICFLOW_STORYBLOK);
  const redirectService = inject(RedirectService);
  const affiliateService = inject(AffiliateService);
  const notFoundGuard = inject(NotFoundGuard);
  const platformId = inject(PLATFORM_ID);

  const path = getAllRouteData(route).storyblokPathOverride || getFullPath(route).join('/') || 'home';

  const story = await firstValueFrom(
    storyblokService.getStory(path, route).pipe(map(resp => (resp && resp.story) || (false as const)))
  );

  if (!story) {
    const path = getFullPath(route);
    const first = path[0];
    if (path.length === 1 && first && !first.includes('.')) {
      const affiliate = await affiliateService.getAffiliate(first);
      if (affiliate) return await affiliateService.affiliateRedirect(affiliate);
    }
    return notFoundGuard.canActivate();
  }
  if (story.content.component === 'Redirect') {
    return redirect(storyblokService.getUrlLink(story.content.redirectTo), story.content.redirectType, redirectService);
  }

  if (isPlatformBrowser(platformId)) {
    const source = story.content.defaultUtmSource;
    const medium = story.content.defaultUtmMedium;
    const content = story.content.defaultUtmContent;

    if (source || medium || content) {
      if (!route.queryParams.utm_source && !route.queryParams.utm_medium && !route.queryParams.utm_content) {
        return redirectService.redirectTo(getFullPath(route), {
          queryParams: {
            ...route.queryParams,
            utm_source: source,
            utm_medium: medium,
            utm_content: content,
          },
        });
      }
    }
  }

  if (story.content.affiliate) {
    const relationships = await firstValueFrom(storyblokService.resolveRelationships(route));
    const affiliate = relationships.find(r => r.uuid === story.content.affiliate);
    if (route.queryParams.ref !== affiliate.slug) {
      return redirectService.redirectTo(story.full_slug.split('/'), {
        queryParams: {
          ...route.queryParams,
          source: affiliate.content.source,
          medium: affiliate.content.medium,
          campaign: affiliate.content.campaign,
          ref: affiliate.slug,
        },
      });
    }
  }

  if (route.queryParams.ref) {
    const affiliate = await affiliateService.getAffiliate(route.queryParams.ref);
    if (affiliate) {
      await affiliateService.handleAffiliate(affiliate);
    }
  }

  await storyblokService.loadComponents(story);

  return true;
};
