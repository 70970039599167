import { Routes } from '@angular/router';
import { templateCanMatch } from './compare/template-can-match';
import { growthBookGuard } from './guards/growth-book.guard';
import { LoggedInRedirectGuard } from './guards/logged-in-redirect.guard';
import { NotFoundGuard } from './guards/not-found.guard';
import { PromoSaveGuard } from './guards/promo-save.guard';
import { TrackingIdGuard } from './guards/tracking-id.guard';
import { routes as storyblokRoutes } from './storyblok/routes';

const allRoutes: Routes = [
  {
    path: 'convert/:format',
    loadChildren: () => import('./tools/convert/routes').then(m => m.routes),
  },
  {
    path: 'compare',
    loadChildren: () => import('./compare/routes').then(m => m.routes),
    canMatch: [templateCanMatch],
  },
  {
    path: 'updates/:updateSlug',
    loadChildren: () => import('./updates/routes').then(m => m.routes),
  },
  {
    path: 'update-template',
    loadChildren: () => import('./updates/routes').then(m => m.routes),
  },
  { path: 'a', loadChildren: () => import('./account/routes').then(m => m.routes) },
  { path: 'r', loadChildren: () => import('./referral/routes').then(m => m.routes) },
  { path: 'auth', loadChildren: () => import('./auth/routes').then(m => m.routes) },
  {
    path: 'cant-edit-gallery',
    loadChildren: () => import('./public-cant-edit-gallery/routes').then(m => m.routes),
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./maintenance/routes').then(m => m.routes),
  },
  {
    path: 'iframe-auth',
    loadChildren: () => import('./iframe-auth/routes').then(m => m.routes),
  },
  {
    path: 'technical',
    loadChildren: () => import('./technical/routes').then(m => m.routes),
  },
  { path: 'sso-callback', loadChildren: () => import('./auth/sso-callback-routes').then(m => m.routes) },
  {
    path: '',
    children: storyblokRoutes,
  },
  {
    path: '**',
    canActivate: [NotFoundGuard],
    loadComponent: () => import('./pages/not-found-page/not-found-page.component').then(m => m.NotFoundPageComponent),
  },
];

export const routes: Routes = [
  {
    path: '',
    canActivate: [LoggedInRedirectGuard, TrackingIdGuard, PromoSaveGuard, growthBookGuard],
    children: allRoutes,
  },
];
